import React, { useState, useEffect } from "react";
import "../assets/css/Header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
var Loader = require("react-loader");

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [tours, setTours] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const tourData = require("../Torudetails.json");

    const tours = tourData.tours;
    const sortedTours = tours;
    const limitedData = sortedTours.slice(0, 12);
    limitedData.sort((a, b) => {
      return (
        new Date(a.tourDetails.dates.startDate) -
        new Date(b.tourDetails.dates.startDate)
      );
    });
    if (limitedData) {
      setTimeout(() => {
        setTours(limitedData);
        setLoaded(true);
      }, 300);
    }
  }, []);

  const headerStyle = {
    background:
      location.pathname !== "/list" &&
      location.pathname !== "/about-us" &&
      location.pathname !== "/contactus" &&
      location.pathname !== "/terms" &&
      location.pathname !== "/privacy"
        ? ""
        : "#53834F",
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const detail = (data) => {
    console.log(data);
    navigate("/detail", { state: { id: data } });
  };
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenuOpen]);

  return (
    <>
      <header className="header w-100 m-0 p-0 " style={headerStyle}>
        <nav className="navbar navbar-expand-lg w-100 mx-2 px-2 m-0 p-0">
          <div className="d-flex  w-100 ">
            <div className="main-logo d-flex">
              <div className="">
                <Link
                  className="navbar-brand text-center text-white px-3 logo"
                  to="/"
                >
                  <div className="text-center">
                    <img src="images/Group 1.png" alt="" /> <br />
                    Shree Raghavendarar Travels
                  </div>
                </Link>
              </div>
              <div class="menu--right  px-0 mt-3" role="navigation">
                <div className="menu-icon" id="menu-icon" onClick={toggleMenu}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="#FFFFFF"
                    className="bi bi-list"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                    />
                  </svg>
                </div>
              </div>

              {isMenuOpen && (
                <Loader loaded={loaded}>
                  <div className="menu-popup" id="menu-popup">
                    <div className="container">
                      <div className="row">
                        <div className="col-12" id="update">
                          <div className="text-left">
                            <Link
                              className="navbar-brand text-left text-white logo1"
                              to="/"
                            >
                              <div className="text-left">
                                <img src="images/Mobile-logo.png" alt="" /> <br />
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-12 helo" id="  ">
                          <Link className="nav-link" to="/">
                            <h4 className="head-side" id="head-side">
                              Home
                            </h4>
                          </Link>
                          <Link className="nav-link" to="/about-us">
                            <h4 className="head-side">About Us</h4>
                          </Link>
                          <Link className="nav-link" to="/list">
                            <h4 className="head-side" id="programs">
                              Upcoming Trips
                            </h4>
                          </Link>
                          {tours.map((tour) => (
                            <div key={tour.id} className="menu-item1">
                              <a onClick={() => detail(tour.id)}>
                                {tour.tourDetails.tourName}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-arrow-right"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                  />
                                </svg>
                              </a>
                            </div>
                          ))}
                          <Link className="nav-link" to="/">
                            <h4 className="head-side" id="liv-veda">
                              Gallery
                            </h4>
                          </Link>
                          <Link className="nav-link" to="/contactus">
                            <h4 className="head-side">Contact</h4>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="popup-close icon-container"
                      onClick={toggleMenu}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        fill="currentColor"
                        class="bi bi-x-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                      </svg>
                    </div>
                  </div>
                </Loader>
              )}
            </div>

            <div
              className="mega-menu text-center  w-100 collapse navbar-collapse  d-lg-block "
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav w-100 text-center  d-flex justify-content-center">
                <li className="nav-item mx-3">
                  <Link className="nav-link text-white" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item mx-3">
                  <Link className="nav-link text-white" to="/about-us">
                    About us
                  </Link>
                </li>
                <li className="nav-item mx-3">
                  <Link className="nav-link text-white" to="/list">
                    Upcoming Trips
                  </Link>
                </li>
                <li className="nav-item mx-3">
                  <Link className="nav-link text-white" to="/">
                    Gallery
                  </Link>
                </li>
                <li className="nav-item mx-3">
                  <Link className="nav-link text-white" to="/contactus">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
