import React, { useState, useEffect } from 'react';


import "../assets/css/Terms.css";
import Footer from "../components/Footer";
import Header from "../components/Header";
var Loader = require('react-loader')

// import "../assets/css/style.css";
const Privacy = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
        setLoaded(true);
      }, 300);
  }, []);
  
  return (
    <>
        <Loader loaded={loaded}>
      <div className="terms-header">
        <Header />
      </div>
      <div className="col-12 text-center head-section">
        <div className="terms-section">
          <h2>Privacy Policy</h2>
        </div>
        <div className="terms">
          <p>
            <b>Shree Raghavendarar Travels- Privacy Policy</b>
          </p>
          <p className="sub-title">
            Your privacy plays an important role for Shree Raghavendarar Travels. We believe
            that customers have the right to know about the privacy policy
            followed by us. Shree Raghavendarar Travels understands the value of information
            that is being collected, used, disclosed and stored.
          </p>
          <p className="sub-title">
            The idea of this privacy policy is to educate the customers of what
            personal information is captured and used by us. We also assure that
            your information will never be shared with any of the third parties
            or at the time of registration.
          </p>

          <p>Collection of Personal Information</p>

          <p className="sub-title">
            Personal information is a valid data that is used to identify a
            particular person and contact them. Shree Raghavendarar Travels requests its
            customers to share their personal information directly or indirectly
            through a third party. However, we do not collect any type of
            information without your permission or knowledge. Our Shree Raghavendarar takes
            reasonable steps to protect the security of your information we
            collect from you.
          </p>

          <p className="sub-title">
            Customers will generally share their personal information in the
            following circumstances:
          </p>

          <p className="sub-title">
            * When you book your tickets or any holiday package through our
            website, then you will be asked for information like email, letter,
            fax or so on.
          </p>

          <p className="sub-title">
            * At the time of registration, the customer service team will
            request you to participate in competitions or subscribe for
            promotional newsletters.
          </p>

          <p className="sub-title">
            * When you take part in surveys, you will be directed to a link
            asking for personal details. Your privacy is 100% protected by us.
          </p>

          <p>Use of Personal Information</p>

          <p className="sub-title">
            We use the personal information of the users in a beneficial way to
            both of us. The collected information is used and transferred to the
            third parties for booking, enquiry and transaction process. You
            should know that the information gathered from you will be processed
            outside India as we need to arrange the products and services
            requested by you. Customers will be requested to confirm the
            acceptance of sharing of information at the time of booking.
          </p>

          <p className="sub-title">
            The personal information collected from you will be used to send Shree Raghavendarar
             latest product announcements, promotions, offers and
            events. We are glad to inform that it helps us to improve our
            products and offerings to a great extent. Customers have the option
            to unsubscribe the announcements at any point of time.
          </p>

          <p className="sub-title">
            We mainly use your information to upgrade our current products,
            services, content and advertising. Sometimes, the collected personal
            information will be used internally for research, analysis and
            auditing.
          </p>

          <p>Use of Non- Personal Information</p>

          <p className="sub-title">
            Non-personal information means the information that does not
            identify a particular user. The list of non-personal information
            include browser, operating system, occupation, language, location,
            pin code and device identifier. Shree Raghavendarar Travels collects this type of
            information to enhance the user experience as well the products and
            services.
          </p>

          <p>Use of Cookies and Technologies</p>

          <p className="sub-title">
            Cookies play an important role in offering the best user experience
            for all our valuable Shree Raghavendarar customers. Most of the web browsers accept
            cookies while others require some changes to be done in your web
            browser. However, the users can easily control and delete the
            required cookies immediately. We make use of cookies to upgrade our
            online services, applications, advertisements and email messages.
            Generally, the cookies are used to identify certain information like
            passwords, list of pages visited, identify your session at a
            particular website and so on.
          </p>

          <p className="sub-title">
            Shree Raghavendarar Travels assure that we do not use cookies for identifying your
            personal information or share your privacy with any of the third
            parties.
          </p>

          <p>Disclosure to Third Parties</p>

          <p className="sub-title">
            Shree Raghavendarar Travels has the right to share the personal information as
            required with any of the third parties. It is mainly shared on
            matters relating to booking, reservation, blocking or any such
            activity initiated by users.
          </p>

          <p>Service Providers and Partners</p>

          <p className="sub-title">
            Shree Raghavendarar Travels Private Limited may disclose the personal information
            with the companies who offers services like information processing,
            developing customer data, conducting customer research and
            satisfaction surveys.
          </p>

          <p>With Whom Your Personal Information is Shared?</p>

          <p className="sub-title">
            We usually share anonymous information and reports to our reputed
            suppliers, advertisers or any of the potential business partners. Shree Raghavendarar Travels
            requests all the customers to read over the privacy policy
            before their booking process. This is because they can gain more
            information about us in a detailed manner.
          </p>

          <p>Changes to this Privacy Policy</p>

          <p className="sub-title">
            Shree Raghavendarar Travels reserves the right to change the policy at any time.
            Users are required to change the page periodically as we are not
            responsible if users don’t read over our policy. However, we will
            definitely notify the changes made in the privacy policy to all our
            customers through email or post.
          </p>

          <p className="sub-title">
            If you have any questions or queries, please feel free to contact us
            any time.
          </p>

          <p className="sub-title">
            We are eager to hear your comments regarding our privacy policy. For
            more details, contact us at srisrst2009@gmail.com.in and we will respond
            to your need immediately.
          </p>

          <p>Address</p>

          <p className="sub-title">
          Shree Raghavendarar Travels
          </p>

          <p className="sub-title">
          221, New Scheme Rd, Pappanaickenpalayam,
          </p>

          <p className="sub-title">
          Coimbatore, Tamil Nadu - 641037
          </p>
        </div>
      </div>
      <Footer />
      </Loader>
    </>
  );
};

export default Privacy;
