import React, { useState, useEffect } from 'react';

import "../assets/css/Terms.css";
import Footer from "../components/Footer";
import Header from "../components/Header";
var Loader = require('react-loader')

// import "../assets/css/style.css";
const Terms = () => {
  
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    setTimeout(() => {
        setLoaded(true);
      }, 300);
  }, []);

  
  return (
    <>
        <Loader loaded={loaded}>
      <div className="terms-header">
        <Header />
      </div>
      <div className="col-12 text-center head-section">
        <div className="terms-section">
          <h2>Terms and Conditions</h2>
        </div>
        <div className="terms">
            <p><b>Please Read Carefully</b></p>
          <p className="sub-title">
            The Shree Raghavendarar Travels Private Ltd (‘the Company, ‘we’, ‘us’ and
            ‘our’) accepts bookings subject to the following conditions. We
            wanted to inform that these conditions will be applicable only for
            tour arrangements booked by the clients with the company. Once you
            book your tickets with us, it means that you have accepted our terms
            and conditions in a correct manner. All references in these
            conditions such as “booking”, “tour” or arrangements means the tour
            arrangements and does not specify anything particular. Please ensure
            to read over the terms and conditions in our website as to get a
            clear idea of the respective rights.
          </p>

          <p>1. Your Tour Contract</p>

          <p className="sub-title">The contract placed between the Client and Company (between “you”
            and “our company”) is important to us. We assure that the contract
            placed between us will not be revealed to any of the private third
            parties. Shree Raghavendarar Travels will stand with their customers in
            matters of any dispute, claim or any disputes arise between us.
            However, we are not responsible for the problems caused in your own
            matters or personal. No employee in the company other than the
            director of Shree Raghavendarar Travels has the right to vary or omit any
            of these terms. No promises will be offered for discounts and
            promotions unless it is made in written form.</p>

            <p>2. Secure your Booking</p>

            <p className="sub-title">
              To secure the booking, Shree Raghavendarar Travels requests the customers
              to pay an amount for minimum deposit. This is mandatory for all
              the tour packages and sometimes high deposit amount will be asked
              to cover payment conditions. You need to read over the booking
              conditions and general information of the tour packages selected.
              Any doubts regarding the booking process can be cleared over the
              phone or you can send an email to srisrst2009@gmail.com.in. The tour
              company shall assist you in any kind of tour related matters.
            </p>

            <p>3. Payment for your Tour</p>

            <p className="sub-title">After the deposit, you are required to pay the balance amount
            within a period of one week. In certain cases, the people would be
            asked to deposit the full payment at an earlier stage before the
            start of the holiday. The “balance due date” will be estimated to
            the customer at the time of direct contact in our office or
            through email. In case of non-payment, the tour company has the
            right to cancel your booking at any point of time.
            </p>

           <p> 4. Change in Booking </p>
            
            <p className="sub-title">If you are interested to make any changes in
            the booking, then you are requested to directly visit our branch
            office with your receipts. The changes will be made on the basis of
            certain emergency conditions alone. We do not entertain the people
            to make repeated changes. Minor changes will only be allowed. It
            will be applicable only under certain conditions. 
            </p>
            
            <p>5. Cancellation of Booking </p>
            
            <p className="sub-title">Cancellation of booking is allowed in our tour company. But,
            it merely depends upon the situation. You have the right to cancel
            the tickets provided under certain circumstances. You need to inform
            us through call or email before a stipulated period of time.
            Cancellation charges will be imposed for the customers in few cases.
            It is important to know that the amount paid will not be refunded at
            any cost. </p>
            
            <p>6. Complaints </p>
            
            <p className="sub-title">If you have any complaints regarding the
            holiday arrangements, then you have the right to complain to us at
            srisrst2009@gmail.com.in. We are eager to hear your complaints and solve
            to the best extent. You can specify the type of complaint in the
            mail and send us. Our customer service team will look at the issue
            and get back to you in a period of 24 hours.</p>
            
            <p> 7. Our Responsibility</p>

            <p className="sub-title">for your Tour We ensure to arrange each of the holiday arrangements
            with great care and attention. Shree Raghavendarar Travels is responsible
            for our employees, agents and suppliers at the course of travel.
            Other than that, we are not responsible for any criminal or violent
            act that takes place during the time of travel. You should act
            diligently and enjoy your holiday trip. We advise to stay away from
            all kind of illegal activities in your journey.</p>
            
            <p> 8. Brochure/ Website/ Advertising </p>
            
            <p className="sub-title">The information contained in our website,
            brochure, itineraries and other advertising material is believed to
            be clear and understandable for all the visitors. However, there may
            be errors occasionally and information may be changed at any time.
            We request all our customers to check out the terms and conditions
            before at the time of booking. If you find anything complicated,
            please drop a mail to us at srisrst2009@gmail.com.in </p>
            
            <p>9. No Smoking Policy</p>

            <p className="sub-title">We request all our customers to stay away from smoking at the time
            of travel. Smoking can really kill the lives of you and others. We
            do not permit our customers to smoke in the vehicles. (Please read
            over the terms and conditions as they are set to our respective
            rights. Accepting our terms and conditions can offer a safe
            journey).</p>
          
        </div>
      </div>
      <Footer />
      </Loader>
    </>
  );
};

export default Terms;
